import _ from "lodash";
import $ from "jquery";

const defaults = {
  enableAjax: true,
  selector: {
    main: "#main",
    nav: "#left-panel nav",
    plugins: "#js-plugins"
  },
  staticElements: "#header, #left-panel, #main, div.page-footer, script, style, #js-plugins, div.sf-toolbar, div.pace, .divMessageBox, #divSmallBoxes, #divMiniIcons, #divbigBoxes",
  loginCustomHeader: "X-Auth-Redirect"
};

export default function (app, config) {
  const settings = $.extend(true, {}, defaults, config);
  const obj = this;

  let initialized = false;

  const $main = $(settings.selector.main);
  const $nav = $(settings.selector.nav);
  const $plugins = $(settings.selector.plugins);

  const ajaxError = function (error) {
    // clear memory
    app.pageDestroy();

    document.title = error;

    $main.html(`<h4 class="ajax-loading-error"><i class="fa fa-warning txt-color-orangeDark"></i> ${error}</h4>`);

    window.scrollTo(0, 0);

    app.pageInit();
  };

  const ajaxLoad = function (data) {
    // clear memory
    app.pageDestroy();

    document.title = obj.decodeHTML(data
      .match(/<title>([\s\S]*)<\/title>/)[1]
      .trim());

    const $data = $("<div />").html(data);

    // update navigation if first element href does not match
    //  in case the loaded page does have a different navigation than the current one
    //  for example user administration or different project
    if ($nav.find("> ul > li.first a").attr("href") !== $data.find("#left-panel nav > ul > li.first a").attr("href")) {
      $nav.html($data.find("#left-panel nav").html());
    }
    $data.find("#main script[src]").each(function () {
      console.warn("JavaScript asset referenced in Ajax-#main-Content. Should be put into #jsplugins:", this.src);
    });
    $main.html($data.find("#main").html());

    let scripts = $data.find("#js-plugins,.js-plugins");
    // Prevent loading of js-plugin scripts Encore.enableSingleRuntimeChunk() (runtime.js) multiple times. Even in Production
    // otherwise maybe multiple jquery instances are present
    scripts.find("script[src]").filter(function () {
      if ($(`script[src="${this.src}"]`).length > 0) { // script already sourced
        return false;
      }
      return this.src.match(/\/runtime(\.[0-9a-f]+)?\.js/);
    }).remove();
    scripts = scripts.map(function () { return $(this).html(); }).get().join("");
    $plugins.html(scripts);

    window.scrollTo(0, 0);

    app.pageInit();
  };

  const loadPage = function (url) {
    // stop current page related processing of ajax requests
    app.pageAbort();

    // trigger custom ajax pageunload event
    const event = $.Event("ajaxpageunload");
    $(window).trigger(event);
    if (event.isDefaultPrevented()) {
      return false;
    }

    const { href } = location;
    url = url.split("?").length > 1
      ? `${location.href}&_ajaxLoadPage=1`
      : `${location.href}?_ajaxLoadPage=1`;
    $.ajax({
      type: "get",
      url,
      dataType: "html",
      cache: true,
      async: true,
      beforeSend() {
        // clear all dom elements excluding main components
        //  this will remove potential plugin generated elements
        $("body").find("> *").filter(`:not(${settings.staticElements})`).empty()
          .remove();

        // change navigation active items
        //  match the url and add the active class
        //  first strip parameters from route as it cannot be
        //  matched otherwise
        const simpleUrl = obj.stripUrlParameters(obj.stripBaseUrl(url));
        const activeItems = $(`nav li:has(a[href="${simpleUrl}"])`);
        if (activeItems.length) {
          $("nav li.active").removeClass("active");
          activeItems.addClass("active");
        }
      },
      success(data, textStatus, request) {
        // check for custom login redirect header
        //  if set redirect the window to login page
        if (
          (request.getResponseHeader(settings.loginCustomHeader))
          && (window.location.pathname !== request.getResponseHeader(settings.loginCustomHeader))
        ) {
          window.location.href = request.getResponseHeader(settings.loginCustomHeader);
          return;
        }

        // clear all dom elements again
        const $body = $("body");
        $body.find("> *").filter(`:not(${settings.staticElements})`).empty().remove();

        // remove modal open class from body
        $body.removeClass("modal-open");

        // check if href is still the same after ajax load
        //  prevent freezing of browser window when too many ajax requests
        //  are sent at the same time
        if (href === location.href) {
          ajaxLoad(data);
        }
      },
      error(xhr, ajaxOptions, thrownError) {
        // check for custom login redirect header
        //  if set redirect the window to login page
        if (
          (xhr.getResponseHeader(settings.loginCustomHeader))
          && (window.location.pathname !== xhr.getResponseHeader(settings.loginCustomHeader))
        ) {
          window.location.href = xhr.getResponseHeader(settings.loginCustomHeader);
          return;
        }

        const error = `Error ${xhr.status}! ${thrownError}.`;

        ajaxError(error);
      }
    });

    return true;
  };

  this.decodeHTML = function (string) {
    return $("<div>", { html: `${string}` }).html();
  };

  this.stripBaseUrl = function (string) {
    return string.replace(/^(?:\/\/|[^/]+)*/, "");
  };

  this.stripUrlParameters = function (string) {
    return string
      .replace(/((\d{4}-\d{2}-\d{2}\/)|([\d]+\/))+/, "")
      .replace(/\?_ajaxLoadPage=\d/, "?")
      .replace(/&_ajaxLoadPage=\d/, "")
      .replace(/\?$/, "");
  };

  this.init = function () {
    if (!initialized) {
      initialized = true;

      if (settings.enableAjax) {
        let popped = ("state" in window.history);
        const initialURL = location.href;

        // Defer the popstate event creation because ios and safari
        // send an initial popstate for some reason.
        _.defer(() => {
          $(window).on("popstate", () => {
            const initialPop = !popped && location.href === initialURL;
            popped = true;
            if (initialPop) {
              return;
            }

            loadPage(location.href);
          });
        });

        $(document).on("click", 'a:not([data-action="userLogout"])', function (e) {
          const url = $(this).attr("href");

          if (url === undefined || $(this).attr("target") === "_blank" || $(this).hasClass("no-ajax")) {
            return true;
          }
          if (e.ctrlKey || e.shiftKey) {
            return true;
          }

          if (obj.stripBaseUrl(location.href) !== obj.stripBaseUrl(url)) {
            return obj.goTo(url);
          }

          return false;
        });
      }
    }
  };

  this.goTo = function (url) {
    if (settings.enableAjax) {
      // exclude external links, links with hashtags and symfony profiler links
      if (url !== undefined
                && (url.indexOf(document.domain) > -1 || url.indexOf(":") === -1)
                && url.indexOf("#") === -1
                && url.indexOf("_profiler") === -1) {
        history.pushState({}, "", url);
        loadPage(url);
        return false;
      }
      return true;
    }
    window.location.href = url;
    return false;
  };

  this.destroy = function () {};
}
